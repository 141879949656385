import React, { Fragment } from "react";
import  Resource from '../Components/Resources/Resource.js';
import SHeaderbar from "../Components/Navbar/Header.js";
import SFooterbar from "../Components/Navbar/Footer.js";

function Resources() {
  const rolename = localStorage.getItem("role");
  return (
    <div> 
    <SHeaderbar/>
	{rolename=='parent' && (
	<>
		<div class="app-wrapper">
				<div class="app-content pt-3 p-md-3 p-lg-4">
					<div class="container-xl">
						<div class="row g-4 mb-4 weekly-calendar">
							<div class="col-12 col-lg-9">
								<h5>Parent Dashboard</h5>
							</div> 
						</div> 
					</div>
				</div>
				<SFooterbar />
		</div>
	</>
	)}
	{rolename=='student' && (
    <div class="app-wrapper">	    
	    <div class="app-content pt-3 p-md-3 p-lg-4">
		    <div class="container-xl"> 
			    <div class="row g-4 mb-4" onclick="window.location.href='/practice_tests';">  
				    <div class="col-12 col-lg-4">
					    <div class="app-card app-card-basic d-flex flex-column align-items-start shadow-sm">
						    <div class="app-card-header p-3 border-bottom-0">
						        <div class="row align-items-center gx-3">
							        <div class="col-auto">
								        <div class="app-icon-holder">
											  <i width="1em" height="1em" class="fa fa-tasks"></i>
									    </div>
						                
							        </div>
							        <div class="col-auto">
								        <h4 class="app-card-title">SAT Practice Test</h4>
							        </div>
						        </div>
						    </div>
						    <div class="app-card-body px-4">
							    <div class="intro"></div>
						    </div>
						    <div class="app-card-footer p-4 mt-auto">
							   <a class="btn app-btn-secondary" href="/practice_tests">Click here to take a practice SAT</a>
						    </div>
						</div>
				    </div>  
				    <div class="col-12 col-lg-4" onclick="window.location.href='/act_practice_tests';">
					    <div class="app-card app-card-basic d-flex flex-column align-items-start shadow-sm">
						    <div class="app-card-header p-3 border-bottom-0">
						        <div class="row align-items-center gx-3">
							        <div class="col-auto">
								        <div class="app-icon-holder">
											<i width="1em" height="1em" class="fa fa-tasks"></i>
									    </div>
						                
							        </div>
							        <div class="col-auto">
								        <h4 class="app-card-title">ACT Practice Test</h4>
							        </div>
						        </div>
						    </div>
						    <div class="app-card-body px-4">
							    
							    <div class="intro"></div>
						    </div>
						    <div class="app-card-footer p-4 mt-auto">
							   <a class="btn app-btn-secondary" href="/act_practice_tests">Click here to take a practice ACT</a>
						    </div>
						</div>
				    </div>  
			    </div> 
		    </div>
	    </div>
	    
	    <SFooterbar/>
    </div>
	)}
    </div>
  );
}

export default Resources; 
