import React from 'react'
import { Fragment } from 'react'
import AdminLogin  from '../Components/Admin/AdminLogin/AdminLogin'

function adminLogin() {
  return (
    <Fragment>
        <AdminLogin />
    </Fragment>
  )
}

export default adminLogin