
import React from "react";
import { Navbar, Nav, Container, Button, Form } from "react-bootstrap";
function SFooterbar() {
  return (
    <>
      <div className="footer">
          <p>&copy; 2023 - 2024 Brilliant Prep. All rights reserved</p>
        </div>
    </>
  );
}

export default SFooterbar;

