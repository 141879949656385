import React from 'react'
import AdminRegister from '../Components/Admin/AdminRegister/AdminRegister'


function adminRegister() {
  return (
    <>
    <AdminRegister />
    </>
  )
}

export default adminRegister