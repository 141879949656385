import React from 'react';
import DesmosCalculator from "../Components/DesmosCalculator";

function Calculator() {
  return (
    <div>
      <DesmosCalculator />
    </div>
  );
}

export default Calculator;