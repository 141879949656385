import React, { Fragment } from 'react'
import Register from "../Components/Register/Register"

function userRegister() {
    return (
        <Fragment>
            <Register />
        </Fragment>
    )
}

export default userRegister; 