import React from 'react'
import NavBar from '../Components/Admin/NavBar'
import EditUser from '../Components/Admin/EditUser'

function editUser() {
  return (
    <>
    <NavBar/>
    <EditUser/>
    </>
  )
}

export default editUser