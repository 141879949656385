import React from 'react'
import NavBar from '../Components/Admin/NavBar'
import AddUser from '../Components/Admin/AddUser'
function addUser() {
  return (
    <>
    <NavBar/>
    <AddUser/>
    </>
  )
}

export default addUser