import React from "react";
function SFooterbar() {
  return (
    <>
      <footer class="app-footer">
		    <div class="container text-center py-3">
            <small class="copyright">Copyright &copy; - 2024 Brilliant Prep. All rights reserved. All rights reserved.</small>		       
		    </div>
	    </footer>	
    </>
  );
}

export default SFooterbar;

